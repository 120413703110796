"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RegisterErrorHandler = void 0;
var RegisterErrorHandler = (function () {
    function RegisterErrorHandler(window) {
        this.window = window;
    }
    RegisterErrorHandler.prototype.init = function (bugfender) {
        this.window.addEventListener('error', function (e) {
            var msg = e.message, line = e.lineno, filename = e.filename, error = e.error;
            var stack;
            if (error && error.stack) {
                stack = error.stack;
            }
            if (typeof msg === 'string') {
                bugfender.sendCrash(msg, [
                    "Error: " + msg,
                    "URL: " + (filename ? filename : 'N/A'),
                    "Line: " + (line ? line : 'N/A'),
                    "Stack: " + (stack ? stack : 'N/A'),
                ].join('\n'));
            }
        });
    };
    return RegisterErrorHandler;
}());
exports.RegisterErrorHandler = RegisterErrorHandler;
