"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PrintToConsole = void 0;
var util_1 = require("util");
var log_1 = require("../types/log");
var PrintToConsole = (function () {
    function PrintToConsole(console) {
        this.console = console;
    }
    PrintToConsole.prototype.init = function (printToConsole) {
        this.printToConsole = printToConsole;
    };
    PrintToConsole.prototype.error = function () {
        var _a;
        var parameters = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            parameters[_i] = arguments[_i];
        }
        if (this.printToConsole) {
            (_a = this.console).error.apply(_a, parameters);
        }
    };
    PrintToConsole.prototype.info = function () {
        var _a;
        var parameters = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            parameters[_i] = arguments[_i];
        }
        if (this.printToConsole) {
            (_a = this.console).info.apply(_a, parameters);
        }
    };
    PrintToConsole.prototype.log = function () {
        var _a;
        var parameters = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            parameters[_i] = arguments[_i];
        }
        if (this.printToConsole) {
            (_a = this.console).log.apply(_a, parameters);
        }
    };
    PrintToConsole.prototype.trace = function () {
        var _a;
        var parameters = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            parameters[_i] = arguments[_i];
        }
        if (this.printToConsole) {
            (_a = this.console).trace.apply(_a, parameters);
        }
    };
    PrintToConsole.prototype.warn = function () {
        var _a;
        var parameters = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            parameters[_i] = arguments[_i];
        }
        if (this.printToConsole) {
            (_a = this.console).warn.apply(_a, parameters);
        }
    };
    PrintToConsole.prototype.printLog = function (log) {
        if (this.printToConsole) {
            var levelToMethod = ['log', 'warn', 'error', 'trace', 'info', 'error'];
            var method = levelToMethod[log.level || log_1.LogLevel.Debug];
            var tag = log.tag ? "[" + log.tag + "] " : '';
            var location_1 = [log.file || '', log.method || '', log.line || ''].filter(function (p) { return p !== ''; }).join(':');
            var message = "" + tag + util_1.format(log.text) + " " + location_1;
            this.console[method](message);
        }
    };
    return PrintToConsole;
}());
exports.PrintToConsole = PrintToConsole;
