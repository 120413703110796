"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.OverrideConsoleMethods = void 0;
var log_1 = require("../types/log");
var OverrideConsoleMethods = (function () {
    function OverrideConsoleMethods(storage, window) {
        this.storage = storage;
        this.window = window;
    }
    OverrideConsoleMethods.prototype.init = function () {
        var self = this;
        this.window.console = function (console) {
            return __assign(__assign({}, console), { log: function () {
                    var parameters = [];
                    for (var _i = 0; _i < arguments.length; _i++) {
                        parameters[_i] = arguments[_i];
                    }
                    console.log.apply(console, parameters);
                    self.storage.addLogMessage(log_1.LogLevel.Debug, parameters);
                }, trace: function () {
                    var parameters = [];
                    for (var _i = 0; _i < arguments.length; _i++) {
                        parameters[_i] = arguments[_i];
                    }
                    console.trace.apply(console, parameters);
                    self.storage.addLogMessage(log_1.LogLevel.Trace, parameters);
                }, info: function () {
                    var parameters = [];
                    for (var _i = 0; _i < arguments.length; _i++) {
                        parameters[_i] = arguments[_i];
                    }
                    console.info.apply(console, parameters);
                    self.storage.addLogMessage(log_1.LogLevel.Info, parameters);
                }, warn: function () {
                    var parameters = [];
                    for (var _i = 0; _i < arguments.length; _i++) {
                        parameters[_i] = arguments[_i];
                    }
                    console.warn.apply(console, parameters);
                    self.storage.addLogMessage(log_1.LogLevel.Warning, parameters);
                }, error: function () {
                    var parameters = [];
                    for (var _i = 0; _i < arguments.length; _i++) {
                        parameters[_i] = arguments[_i];
                    }
                    console.error.apply(console, parameters);
                    self.storage.addLogMessage(log_1.LogLevel.Error, parameters);
                } });
        }(this.window.console);
    };
    return OverrideConsoleMethods;
}());
exports.OverrideConsoleMethods = OverrideConsoleMethods;
