"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SessionStorageKey = exports.LocalStorageKey = void 0;
var LocalStorageKey;
(function (LocalStorageKey) {
    LocalStorageKey["AppID"] = "bf_app_id";
    LocalStorageKey["DeviceData"] = "bf_device_data";
    LocalStorageKey["DeviceUDID"] = "bf_device_udid";
})(LocalStorageKey = exports.LocalStorageKey || (exports.LocalStorageKey = {}));
var SessionStorageKey;
(function (SessionStorageKey) {
    SessionStorageKey["SessionUDID"] = "bf_session_udid";
    SessionStorageKey["SessionUUID"] = "bf_session_uuid";
})(SessionStorageKey = exports.SessionStorageKey || (exports.SessionStorageKey = {}));
