"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UUIDFactory = void 0;
var UUIDFactory = (function () {
    function UUIDFactory() {
    }
    UUIDFactory.prototype.create = function () {
        var s4 = function () {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        };
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    };
    return UUIDFactory;
}());
exports.UUIDFactory = UUIDFactory;
