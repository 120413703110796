"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LOG_LEVELS = exports.LogLevel = void 0;
var LogLevel;
(function (LogLevel) {
    LogLevel[LogLevel["Debug"] = 0] = "Debug";
    LogLevel[LogLevel["Warning"] = 1] = "Warning";
    LogLevel[LogLevel["Error"] = 2] = "Error";
    LogLevel[LogLevel["Trace"] = 3] = "Trace";
    LogLevel[LogLevel["Info"] = 4] = "Info";
    LogLevel[LogLevel["Fatal"] = 5] = "Fatal";
})(LogLevel = exports.LogLevel || (exports.LogLevel = {}));
exports.LOG_LEVELS = [
    LogLevel.Debug,
    LogLevel.Warning,
    LogLevel.Error,
    LogLevel.Trace,
    LogLevel.Info,
    LogLevel.Fatal,
];
